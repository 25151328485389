module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/blog your pathPrefix should be "blog"
  siteTitle: 'Workshop', // Navigation and Site Title
  siteTitleAlt: 'A software develop venting off about random stuff', // Alternative Site title for SEO
  siteTitleShort: 'Kukiel\'s blog', // short_name for manifest
  siteUrl: 'https://blog.kukiel.space', // Domain of your site. No trailing slash!
  siteLanguage: 'en', // Language Tag on <html> element
  siteLogo: 'images/logo.png', // Used for SEO and manifest, path to your image you placed in the 'static' folder
  siteDescription: 'I don\'t know what i\'m doing!',
  author: 'Kukiel', // Author for schemaORGJSONLD
  organization: 'KukielCorps LLC',

  // siteFBAppID: '123456789', // Facebook App ID - Optional
  userTwitter: '@gkm2806', // Twitter Username
  ogSiteName: 'KukielCorps Blog', // Facebook Site Name
  ogLanguage: 'en_US',
  googleAnalyticsID: '',

  // Manifest and Progress color
  themeColor: '#5348FF',
  backgroundColor: '#2b2e3c',

  // Social component
  twitter: 'https://youtu.be/dQw4w9WgXcQ',
  twitterHandle: '@gkm2806',
  github: 'https://github.com/gkm2806/',
  linkedin: 'https://www.linkedin.com/in/gkm2806/',
}
